import React from "react"

import Layout from "../components/Layout"
import Wrapper from "../components/Wrapper/Wrapper"
import Button from "../components/Button/Button"

const ErrorPage = () => {
  return (
    <Layout>
      <Wrapper className="min-h-fit py-48">
        <h2 className="text-center text-7xl text-monochromeSky-lightest ">404</h2>
        <h3 className="mt-7 text-center text-4xl text-monochromeSky-lightest">
          Sorry, requested page can not be found.
        </h3>
        <Button href="/" primary className="mx-auto mt-7">
          Go back home
        </Button>
      </Wrapper>
    </Layout>
  )
}

export default ErrorPage
